.integration-page {
    width: 60%;
    height: 100%;
    padding: 4% 20%;
}




.integration-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}


.ind-integration {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.75rem;
    border-bottom: 1px solid rgb(226, 226, 226);
    cursor: pointer;
    padding: 5px 0.5rem;
    width: 100%;
    position: relative;
}


.ind-integration:hover {
    background: #f7f8f9;
    transition: all .1s ease-in-out;
}


.ind-integration img {
    width: 2rem;
    height: 3rem;
    object-fit: contain;
    border-radius: 5px;
}


.ind-integration p {
    font-size: 15px;
    font-weight: 600;
}


.integration-list-meta-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-left: auto;
    color: gray;
}


.integration-list-meta-container p {
    font-weight: 500 !important;

}