.page-create-form-auth-integration {
    width: 94%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}

.ind-auth-user-form-label {
    min-width: 6rem;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 14px !important;
    text-transform: capitalize;
}

.ind-auth-user-form-value {
    text-align: center;
    overflow: hidden;
    font-size: 14px !important;
}



.auth-user-form-table {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}


.auth-user-form-no-response-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
}



.user-details-table {
    border-collapse: collapse;
    border: 1px solid black;
    border-radius: 10px;
    width: 100%;
}

.user-details-table th,
.user-details-table td {
    border: 1px solid black;
    padding: 8px;
    text-align: center;
    border-radius: 10px;
}