.message-container {
    height: 70vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.formLabel {
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: text;

}

.delete-btn-container {
    width: 96%;

}


.site-heading-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.site-heading-container h1 {
    display: flex;
    gap: 1rem;
    align-items: center;
}


.auth-error-message {
    margin: 0;
    height: 10px;
    color: red;
    font-weight: 500;
    font-size: 13px;
    /* margin-top: -12px; */
    margin-top: -8px;
    margin-bottom: 8px;
    width: 100%;
    margin-left: 12%;

}