.textField {
    color: black;
    border: 1px solid rgb(232, 231, 227);
    font-size: 14px;
    font-weight: 400;
    padding: 0 10px;
    height: 40px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    transition: 0.54s;
    cursor: text;
    background-color: white;
}

.richText-editor {
    border: 1px solid rgb(232, 231, 227);
    border-radius: 5px;
    min-height: 100px;
    padding: 10px;
    background-color: white;
}

input[type="date"] {
    cursor: pointer;
}

.textField:is(:hover, :active),
.auth-field:is(:hover, :active),
.textField:focus-within,
.commentTextField:hover,
.commentTextField:active,
.commentTextField:focus-within {
    border: 1px solid rgb(25, 118, 210);
}

.commentTextField {
    color: black;
    border: 1px solid rgb(232, 231, 227);
    font-size: 14px;
    font-weight: 400;
    padding: 10px;
    height: 100px;
    border-radius: 8px;
    transition: 1s;
    background-color: white;
}

.hiddenField-container {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
}

.hiddenField-container input {
    width: 100%;
    padding-right: 2.7rem;
}

.hiddenField-container>span {
    position: absolute;
    right: 15px;
    cursor: pointer;
}

.auth-field {
    width: 92%;
    height: 40px;
    padding: 0 10px;
    border-radius: px;
    border: 1px solid rgb(232, 231, 227);
    transition: 0.54s;
}

.htmlText-editor {
    display: none;
    border: 1px solid rgb(232, 231, 227);
    border-radius: 5px;
    min-height: 100px;
    padding: 10px;
    background-color: white;
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    margin-top: -0.25rem;
}

.htmlText-editor::-webkit-scrollbar {
    display: none;
}

.login-field-email,
.register-field-name,
.register-field-email,
.register-field-password,
.register-field-confirm-password,
.login-field-password {
    background: linear-gradient(0deg, #e1e9ee, #e1e9ee),
        linear-gradient(0deg, #edf2f5, #edf2f5);
    width: 93.5%;
    height: 75px;
    padding: 0 3%;
    color: black;
}

.login-field-password {
    width: 93.5% !important;
    padding-left: 3%;
    padding-right: 3% !important;
}

.register-field-password,
.register-field-name,
.login-field-email {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.login-field-password,
.register-field-confirm-password,
.register-field-email {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.register-field-email {
    margin-bottom: 0.5rem;
}




/* .google-sheet-form-field {
    width: 100%;
} */


@media screen and (max-width:1026px) {

    .register-field-password,
    .register-field-confirm-password,
    .register-field-email,
    .register-field-name,
    .login-field-email,
    .login-field-password {
        width: 89%;
        padding: 0 5.5%;
    }



}