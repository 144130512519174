.pageList-dropdown {
    position: relative;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: fit-content;
    flex-direction: row-reverse;

}


.formTable-paginate {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 3rem;
}


.pageList-value-container {
    background-color: white;
    border-radius: 5px;
    padding: 0px;
    margin: 0;
    font-size: 25px;
    color: black;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4.5rem;
    gap: 0.25rem;
    cursor: pointer;
    border: 1px solid rgb(232, 231, 227);
}


.pageList-heading {
    font-size: 14px;
}


.pageList-dropdown :where(.css-dev-only-do-not-override-1vtf12y).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 30px !important;
}