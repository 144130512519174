#select-dropdown {
    height: 40px;
    transition: all 0.5s;
}

.ant-select-selector {
    height: 40px !important;
    border-radius: 8px !important;
    display: flex !important;
    align-items: center !important;
}

.ant-select-selector:hover,
.ant-select-selector:active {
    border: 1px solid rgb(25, 118, 210) !important;
}

.ant-select-disabled:where(.css-dev-only-do-not-override-1n7nwfa).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: black !important;
    cursor: default !important;
}


.custom-dropdown-container {
    position: absolute;
    min-width: 7rem;
    padding: 0.75rem 0;
    width: fit-content;
    min-height: 2rem;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px,
        rgba(0, 0, 0, 0.14) 0px 8px 10px 1px,
        rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    /* gap: 1rem; */
    background-color: white;
    z-index: 2;
}


.custom-dropdown-container-form-page {
    right: -120px;
    top: -15px;
}

.custom-dropdown-container-page {
    right: -60px;
}

.custom-dropdown-container-website {
    right: 25px;
}

.custom-dropdown-container-add-website {
    right: 0%;
    top: 80%
}


.custom-dropdown-container-integration {
    right: -15%;
    top: 2%;
}


.custom-dropdown-container button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 0;
    font-size: 15px;
    font-weight: 500;
    width: 100%;
    gap: 0.5rem;
    transition: 0.5s all;


}


.custom-dropdown-container-form-page button {
    padding: 0.5rem;
    width: 90%;
}

.custom-dropdown-container>button:is(:hover) {
    background-color: #b9b9b975;
}


.image-library-filter-container .ant-select {
    width: 15% !important;
}