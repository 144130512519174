.btnStyle {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    padding: 8px;
    color: white;
    border-radius: 5px;
    background-color: rgb(25, 118, 210);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}

.btnStyle:is(:hover) {
    background-color: rgb(21, 101, 192);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
}

.disabled-deleteCloudinary-image-btn,
.disabled-deleteCloudinary-image-btn:hover {
    background-color: #1976d2ba;
    cursor: default;
}

.auth-info-btn {
    color: crimson;
    font-family: "Inter";
    font-weight: 600;
}

.auth-info-btn:is(:hover),
.navigate-btn:is(:hover),
.navigate-btn,
.auth-info-btn {
    background-color: transparent;
    box-shadow: none;
}


.navigate-btn {
    color: black;
}


.auth-submit-btn,
.site-create-btn {
    font-weight: 500;
    height: 30px;
    transition: all 0.5s;
}

.create-block-btn {
    width: 85%;
    position: absolute;
    bottom: 30px;
    z-index: -1;
    /* margin-top: 5rem; */
}



.auth-submit-btn {
    width: 82%;
}

.site-create-btn {

    width: 86%;
}


.nav-bar-save-btn {
    background-color: rgb(82, 155, 238);
}


.nav-bar-save-btn:is(:hover) {
    background-color: rgb(82, 145, 218);
}


.add-ind-list-field-btn {
    width: 14%;
    margin-left: auto;
}

.add-list-field-btn {
    width: fit-content;
}


.link-upload-btn {
    width: 7%;
    margin: 1rem 0 1rem auto;
}


.account-update-btn {
    margin-top: 20%;
    width: 50%;
}


.form-response-btn {
    font-size: 14px;
    background-color: transparent;
    box-shadow: none;
    color: black;
    font-weight: 600;
    border: 1px solid black;
    /* background-color: rgb(21, 101, 192); */
    /* width: 50%; */
}


.form-response-btn:hover {
    color: white;
    border: 1px solid rgb(21, 101, 192);
}


.cloudinary-upload-btn {
    width: 60%;
    /* margin: 1rem 0; */
}


.price-btn {
    width: 90%;
}


.sheet-create-btn {
    margin: 1.5rem auto;
    width: 20%;
    text-transform: capitalize;
}



.login-btn {
    background-color: #6C63FF;
    height: 30px;
    width: 72.5%;
}


.login-btn:hover,
.auth-success-login-btn:hover {
    background-color: #7c77dd;
}

.forgot-password-btn {
    background-color: transparent;
    box-shadow: none;
    color: #888888;
    font-size: 14px;
    font-weight: 400;
}

.forgot-password-login-btn {
    background-color: transparent;
    box-shadow: none;
    color: #888888;
    font-size: 16px;
    font-weight: 500;
}

.forgot-password-btn:hover,
.forgot-password-login-btn:hover {
    background-color: transparent;
    box-shadow: none;
}


.auth-success-login-btn {
    background-color: #6C63FF;
    height: 30px;
    width: 60%;
}

.cors-add-headers-btn {
    margin: 1rem 1.7rem 1rem auto;

}

.setting-add-domain-btn {
    width: fit-content;
    margin: 1rem 9% 1rem auto;
}

.import-website-btn {
    width: fit-content;
    margin: 0 auto;
}

.googleSheet-signin-btn {
    padding: 0;
    padding-right: 4px;
    height: fit-content;
}

@media screen and (max-width:600px) {

    .login-btn,
    .auth-success-login-btn {
        width: 91%;
    }

}


.delete-confirm-btn {
    background-color: crimson;

    &:hover {
        background-color: rgb(181, 50, 76);
    }
}