.block-library {
    width: 30%;
    /* max-width: 0px; */
    box-sizing: border-box;
    height: 100%;
    position: fixed;
    right: 0;
    bottom: 0;
    background-color: white;
    padding: 0.5rem;
    transition: 0.4s ease-in-out;
    box-shadow: 0 1px 13px 4px #00000014;
    z-index: 5;

}


.create-block-field {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;

}


.ind-create-block-field {
    width: 90%;
    /* height: 8rem; */
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}


.block-err-msg {
    color: crimson;
    font-size: 14px;
    font-weight: 500;
    margin-right: auto;
    margin-left: 6%;
    position: relative;
    top: -25px
}


.form-err-msg {
    color: crimson;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    height: 1rem;
}


.form-library-switch-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 87%;
}

.form-library-switch-container label {
    margin-left: -1%;
}