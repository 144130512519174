.popup-container {
    width: 41%;
    height: 28rem;
    border-radius: 10px;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 1rem 0.5rem;
    position: absolute;
    justify-content: center;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    transition: 1s ease-in-out;
}

.popupOverlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(43, 43, 43, 0.3);
    z-index: 16;
    cursor: default;
}

.popup-close-btn {
    display: flex;
    /* margin-left: auto; */
    /* margin-right: 3%; */
}

.popup-heading-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    padding: 0 5%;
    margin-bottom: 1rem;
}

.popup-heading-container h2 {
    margin: 0;
}


.form-response-popup {
    align-items: flex-start !important;
    justify-content: flex-start !important;
    min-width: 70%;
    width: fit-content !important;
    padding: 2rem !important;
    transition: 1s ease-in-out;
    height: 37rem !important;
}

.form-response-popup .popup-heading-container {
    padding: 0;
    width: 98%;
}


.cloudinary-file-upload-popup {
    width: 40%;
    height: 25rem;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 2rem 0;
}


.cloudinary-file-library-popup {
    width: 60%;
    height: 35rem;
    align-items: flex-start;
    justify-content: flex-start;
}


.cloudinary-file-library-popup .popup-heading-container {
    padding: 0 1.25rem;
    width: 95%;
}



.integration-code-popup {
    width: 60%;
    padding: 2rem 0 0 0;
    min-height: 24rem;
    overflow-y: auto;
    justify-content: flex-start;
}


.form-code-popup {
    width: 60%;
    padding: 2rem 0 0 0;
    min-height: 24rem;
    overflow-y: auto;
    justify-content: flex-start;
    height: fit-content;
}




.ga-integration-form-popup {
    width: 40%;
    padding: 2rem 0 0 0;
    height: 27rem;

    justify-content: flex-start;
}

.waitlist-integration-form-popup,
.mixpanel-integration-form-popup,
.clarity-integration-form-popup {
    width: 40%;
    padding: 2rem 0 0 0;
    height: 22rem;
    justify-content: flex-start;
}


.analytics-code-form-popup,
.integration-recaptcha-code-popup {
    width: 60%;
    padding: 2rem 0 1rem 0;
    justify-content: flex-start;
    min-height: 15.5rem;
    overflow-y: auto;
}


.analytics-code-form-popup {
    height: fit-content;
    max-height: 80%;
}


.integration-sheetList-popup {
    height: fit-content;
}


.import-website-popup {
    height: fit-content;
    justify-content: flex-start;
}


.auth-user-response-popup {
    align-items: flex-start !important;
    justify-content: flex-start !important;
    min-width: 60%;
    width: fit-content !important;
    padding: 2rem !important;
    transition: 1s ease-in-out;
    height: 30rem !important;
}



.confirm-popup-container {
    width: 41%;
    border-radius: 10px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0.5rem;
    position: absolute;
    justify-content: center;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    transition: 1s ease-in-out;
    gap: 2rem;
    height: 9rem;
}

.confirm-modal-btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    font-size: 15px;
}