.code-variables-container {
    background-color: rgb(40, 42, 54);
    width: 100%;

}




.code-variables-container>pre {
    /* width: 100%; */
    text-align: center;
    position: relative;
    /* left: -4.5rem; */
    top: -2rem;

    line-height: 1;
}