.uploadImg-container input[type='file'] {
    display: none
}


.uploadFile-container input[type='file'] {
    z-index: -1;
    opacity: 0;

}


.upload-file-design {
    width: 100%;
    height: 10rem;
    background-color: rgb(233, 233, 233);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 5px;
    flex-direction: column;
}


#cloud-upload-icon {
    font-size: 64px;
}


.upload-file-design p {
    margin: 0;
    font-size: 15px;
    font-weight: 600;
}