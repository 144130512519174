.analytics-page {
    width: 60%;
    height: 100%;
    padding: 4% 20%;
}


.analytics-chart-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 1rem;
    margin: 2rem 0;
}


.analytics-linechart {
    width: 80%;
    height: 100%;
}

.chart-container {
    box-shadow: 0px 0px 10px 0px #26095E26;
    padding: 2rem 1rem;
    border-radius: 8px;
}

.analytics-graph-tooltip {
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    width: 15rem;
    gap: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.analytics-graph-tooltip p {
    margin: 0;
    font-size: 14px;
}

.analytics-graph-tooltip p span {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    font-size: 12px;
    color: #8d919f;
}


.analytics-filter-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    width: 100%;
}


.analytics-ind-filter-container {
    box-shadow: 0px 0px 10px 0px #26095E26;
    padding: 2rem 1rem;
    border-radius: 8px;
    width: fit-content;
    height: 2rem;
}


.analytics-msg-container {
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.analytics-axis-label {
    font-size: 12px;
}


.analytics-ind-filter-container .ant-select {
    width: 13rem !important;
    overflow: hidden;
}