.settings-domain-container {
    margin: 2rem 0;
    width: 100%;
}

.settings-domain-heading-container p:nth-child(2) {

    color: #8d919f;
    font-size: 14px;
}


.settings-domainList-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 47%;
    margin-bottom: 1rem;
}


.settings-ind-domainList-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    width: 100%;
}

.settings-ind-domainList-container input {
    width: 85%;
}


.domain-loader-container {
    height: 8rem;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}