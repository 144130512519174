.ind-integration-page {

    width: 60%;
    height: 100%;
    padding: 4% 20%;
}


.integration-form-popup {
    padding: 2rem 0;
    min-height: 30rem;
    overflow-y: auto;
    justify-content: flex-start;
}


.recaptcha-site-details,
.waitlist-site-details {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.recaptcha-site-details>section,
.waitlist-site-details section {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    max-width: 15rem;
}

.recaptcha-site-details p,
.waitlist-site-details p {
    font-weight: 600;
    font-size: 15px;
}

.waitlist-site-details p:nth-child(1) {
    font-size: 17px;
}


.recaptcha-site-details p:nth-child(1) {
    max-width: 12rem;
    font-size: 17px;
}

.recaptcha-site-list-container,
.integration-ind-selected-sheets-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.75rem;
    border-bottom: 1px solid rgb(226, 226, 226);
    cursor: pointer;
    padding: 5px 0.5rem;
    width: 100%;
}


.code-display-container {

    padding: 0.5rem 1rem;
    /* background-color: rgb(40, 42, 54); */
}


pre {
    /* background-color: #283142; */
    padding: 5px 20px;
    font-Family: Courier, monospace;
    font-Size: 15px !important;
    overflow-y: auto;
    line-Height: 1.5;
    margin: 0;
    white-Space: pre-wrap;
    /* word-Wrap: break-word; */

}

#code-copy-icon {
    /* font-size: 18px; */
    color: white;
}

.copy-code-btn {
    position: absolute;
    top: 20%;
    right: 5%;
}

.copy-analytics-code-btn {
    position: absolute;
    top: 26%;
    right: 5%;
}

.copy-mixpanel-code-btn {
    position: absolute;
    top: 18%;
    right: 5%;
}

.recaptcha-domain-field-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.recaptcha-domain-ind-field-container {
    display: flex;
    align-items: center;

    gap: 0.5rem;
    width: 80%;
}

.recaptcha-domain-ind-field-container input {
    width: 70%;
}


.ind-captcha-create-field {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    width: 90%;
    min-height: 5rem;
}


.add-field-recaptcha-btn {
    width: 20%;
    margin-right: auto;
    margin: 0.5rem;
}


.cloudinary-file-upload-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
}


.cloudinary-upload-file-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
}


.cloudinary-upload-ind-file-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    width: 60%;
    border-bottom: 1px solid rgb(226, 226, 226);
}


.cloudinary-upload-ind-file-list p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 12rem;
}


.cloudinary-link-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.cloudinary-ind-link-container {
    width: 100%;
    border-bottom: 1px solid rgb(226, 226, 226);
    padding: 1rem 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.cloudinary-ind-link-container img {
    height: 40px;
    width: 40px;
    border-radius: 5px;
}

.cloudinary-ind-link-container a {
    text-decoration: none;
    color: black;
    font-weight: 600;
    font-size: 15px;
}


.cloudinary-ind-link-container:hover a {
    color: rgb(82, 145, 218);
}


.ind-integration-page .cloudinary-image-content {
    height: fit-content;
    min-height: 29rem;
}

.ind-integration-page .image-library-filter-container {
    margin: 1rem 0;
}

.cloudinary-ind-image-name {
    font-size: 14px;
    width: 16rem;
    font-weight: 600;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.cloudinary-ind-image-date {
    font-size: 14px;
    font-weight: 500;
}



.code-content {
    height: fit-content;
    min-height: 10rem;
    flex-direction: column;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.integration-ind-selected-sheets-container p:nth-child(2),
.recaptcha-site-details p:nth-child(2) {
    font-size: 14px;
    font-weight: 500;
    color: grey;
}


.googlesheet-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 1rem;
}

.integration-selected-sheets-container {
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 0.75rem;
}

.integration-ind-selected-sheets-container {
    justify-content: space-between;
    position: relative;
}

.integration-ind-selected-sheets-container p {
    margin: 8px 0;
}

.integration-ind-selected-sheets-container p:nth-child(1) {
    font-weight: 600;
    font-size: 18px;
    text-transform: capitalize;
    display: flex;
    gap: 0.25rem;
}

.googlesheet-ind-form-field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;

}

.googlesheet-ind-form-field,
.googlesheet-ind-form-field textarea,
.googlesheet-ind-form-field input {
    width: 90%;

}


.googlesheet-ind-form-field .ant-select {
    width: 94% !important;

}


.integration-sheetList-container .form-err-msg {
    margin-left: 5%;
}


.cors-integration-container {
    height: 29rem;
    overflow-y: auto;
}


.cors-integration-headers-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
}


.cors-integration-ind-headers-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    width: 100%;
}


.cors-header-delete-btn {
    width: 3rem;
}

.googleSheet-signin-icon {
    height: 2.5rem;
    padding: 2px 12px;
    width: 1.5rem;
    object-fit: contain;
    background-color: white;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.integration-ind-selected-sheets-detail {
    display: flex;
    align-items: center;
    gap: 0.5rem;

}