.id-tooltip-copy-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}

.id-tooltip-container {
    padding: 2px 1px;
}


.id-tooltip-container p {
    font-weight: 600;
    margin: 0;

}