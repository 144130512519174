.blocks-wrapper {
    width: 100%;
    display: flex;
    height: 100vh;
    overflow: hidden;

}


.block-page {
    width: 60%;
    height: 100%;
    padding: 5% 5% 5%;
    margin-left: auto;
    margin-right: auto;
    overflow: auto;
}


.block-list-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 1rem 0;
    padding-bottom: 5rem;
}


.block-field {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    width: 100%;

}

.nested-grp-container,
.nested-list-container {
    background-color: #e3e3e3 !important;
}


/* .block-library-content {
    display: none;
} */



.list-field-container {
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    gap: 1rem;
    background-color: #f7f8f9;
    border-radius: 5px;
}

.link-block-container {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 0.5rem;
}

.link-block-container input {
    width: 100%;
}

.list-label,
.form-label {
    font-size: 22px;
}


.form-field-container {
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    gap: 1rem;
    background-color: #f7f8f9;
    border-radius: 5px;
}


.form-ind-field-container {
    display: flex;
    flex-direction: column;
    /* width: 90%; */
    height: 62px;
    /* height: 3rem; */
}


.form-ind-field {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 1rem;
}

.form-ind-field-container input {
    width: 96%;
}

.form-addField-btn {
    width: 10%;
    margin-left: auto;
    margin-right: 30px;
}


.form-save-btn {
    width: 15%;
    margin: 0.5rem auto 0.5rem 0;
}

.form-err-msg {
    color: red;
    font-size: 14px;
}

.form-field-label-container,
.form-field-label {
    display: flex;
    align-items: center;
    /* gap: 0.5rem; */
}

.form-field-label {
    gap: 0.75rem;
    width: 60%;
}

.form-moreInfo-container {
    position: relative;
    width: 10%;
}


.form-field-label-container {
    justify-content: space-between;
}





.form-label-container {
    border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    width: 100%;
}

.ind-form-value-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    border-top: none;
    width: 100%;


}

.form-value-container {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: #8d919f;
    ;
}



.ind-form-label {
    min-width: 6rem;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}


.form-label-heading {
    font-weight: 600;
    text-transform: capitalize;
}

.form-table {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: center; */
    width: 100%;
    height: 100%;
}


.form-no-response-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
}

.form-table-content {
    overflow: auto;
    max-height: 25rem;
    max-width: 940px;
    margin: 1rem 0;
    padding: 0.5rem 0.75rem 0.5rem 0;
}


.form-filter-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    width: 100%;

}

.form-filter-container p {
    font-weight: 600;
    font-size: 17;
}




.form-filter-container .select-dropdown {
    width: 10rem !important;
}


.form-table-paginate-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 92%;
    position: absolute;
    bottom: 10px;
}


.image-library {
    width: 100%;
    /* padding: 0 0.25rem; */
}


.cloudinary-image-content {
    display: flex;
    flex-direction: column;
    height: 25rem;
    overflow-y: auto;
    width: 100%;
}


.cloudinary-ind-image-content {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: space-between;
    width: 94%;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid rgb(226, 226, 226);
    cursor: pointer;

}

.cloudinary-ind-image-content:is(:hover) {
    background-color: rgb(226, 226, 226);
}

.cloudinary-ind-image-content section {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: flex-start;
}


.cloudinary-ind-image-content img {
    height: 40px;
    width: 40px;
    border-radius: 5px;
    object-fit: cover;
}



.image-library-filter-container {
    margin: 1rem;
    /* width: 100%; */
    display: flex;
    gap: 0.5rem;
    align-items: center;
    transition: all 0.5s;
}

.image-library-filter-container input:nth-child(1) {
    width: 80%;
}


.cloudinary-image-pagination {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}


.cloudinary-image-pagination button {
    border: 1px solid rgb(226, 226, 226);
    border-radius: 5px;
    padding: 4px;
    margin: 1rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px !important;
}


.cloudinary-image-pagination button:hover {
    background-color: rgb(226, 226, 226);
}

#cloudinary-paginate-icon {
    font-size: 16px;
}


.block-response-container {
    height: fit-content;
    min-height: 10rem;
    flex-direction: column;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.block-response-container pre {
    padding: 40px 20px;
    width: 85%;
    word-Wrap: break-word;
}



.block-response-popup {
    max-height: 60vh;
}


.form-category {
    width: 12rem !important;
}


.confirm-delete-msg {
    text-align: center;
    font-weight: 600;
    margin: 0;
    font-size: 14;

}