.plan-description {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}


.plan-description p {
    width: 80%;
    color: #5b6278;
    line-height: 1.5rem;
}

.plan-description h1 {
    color: #1b243f;
    font-weight: 700;
    font-size: 30px;
    margin-bottom: 4px;
}


.plans-details-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    gap: 2rem;
}


.ind-plan-details-container {
    background: #fff;
    border-radius: 10px;
    border: 1px solid #edeff2;
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2.5rem 0.5rem 1rem 0.5rem;
    gap: 1rem;
    box-shadow: 0px 0px 32px 0px #26095E26;
    position: relative;

}


.ind-plan-details-container p {
    margin: 0 0 4px 0;
}


.ind-plan-name {
    font-weight: 600;
    font-size: 20px;

}

.ind-price-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.ind-price-container span {
    color: #5b6278;
    font-size: 14px;
}

.ind-plan-price {
    font-size: 2.8rem;
    margin: 0;
    display: flex;
    flex-direction: row-reverse;
}

.ind-plan-price sup {
    font-size: 16px;
    font-weight: 600;
}


.ind-plan-description {
    font-size: 15px;
    font-weight: 600;
}


.plan-features-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}


.ind-plan-feature-heading {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
}


.ind-plan-feature {
    margin: 0;
    font-weight: 500;
    font-size: 14px;
    color: #5b6278;
    text-align: center;
    text-wrap: wrap;
    width: 13rem;
}


.ind-plan-feature span {
    color: green;
}


.current-plan-text {
    margin: 0 !important;
    position: absolute;
    top: 0px;
    background-color: rgb(211, 185, 36);
    color: white;
    padding: 4px 5px;

}

.subscription-btn-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}


.subscription-btn-container h1 {
    margin: 0;
}

.email-subscription-description {
    font-size: 14px;
    color: #8d919f;
}