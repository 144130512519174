.block-list {
    width: 300px;
    box-sizing: border-box;
    position: relative;
    left: 0;
    min-height: 100vh;
    background-color: white;
    padding: 4rem 0.5rem;
    transition: 0.4s ease-in-out;
    box-shadow: 0 1px 13px 4px #00000014;
    overflow: auto;
}

.blocklist-open-btn {
    position: fixed;
    top: 4.5rem;
    left: 10px;
}


.blocklist-title {
    width: 100%;
    border-radius: 6px;
    font-weight: 600;
    height: 40px;
    padding-left: 5px;
    margin-bottom: 0.25rem;

    &:hover {
        background-color: #f7f8f9;
    }
}

.blockList-bg-color {
    background-color: #f7f8f9;
    font-weight: 600;
}


.nested-blocklist {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}


.ind-blocklist {
    margin-bottom: 0rem;

}

.nested-block-list-item {
    height: 40px;
    padding-left: 5px;

    &:hover {
        background-color: #f7f8f9;
    }
}