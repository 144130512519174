.site-page {
    width: 60%;
    height: 100%;
    padding: 4% 20%;
}



.site-list-container {
    display: flex;
    align-items: center;
    gap: 1.8rem;
    flex-wrap: wrap;

}


.ind-site-list-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 45%;
    border: 1px solid gray;
    border-radius: 4px;
    padding: 1rem 0.5rem;
    height: 10rem;
    cursor: pointer;
}

/* .ind-site-list-container:is(:hover) {
    background: #f7f8f9;
    transition: all .1s ease-in-out;
} */

.ind-site-list-container p {
    margin: 0;
}

.ind-site-details {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    position: relative;
}

.ind-site-details section {
    display: flex;
    align-items: center;
    gap: 0.25rem;
}


.ind-site-details p:nth-child(1) {
    max-width: 15rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: 600;

}


.ind-site-actions {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
}


.site-update-details label,
.site-id,
.site-last-update {
    font-size: 12px;
    font-weight: 500;
    color: grey;
}


.site-create-form {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
}


.ind-site-create-field {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 60%;
    height: 5rem;
}

.ind-site-create-comment-field {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 60%;
    height: 10rem;
}

.ind-site-create-field label,
.ind-site-create-comment-field label {
    font-weight: 500;
}


.import-website-container {
    width: 100%;
    height: 15rem;
    display: flex;
    align-items: center;
    justify-content: center;
}


.import-website-details {
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.import-website-details-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    background-color: #f7f8f9;
    border-radius: 8px;
    padding: 0 12px;
}

.import-website-details-container p:nth-child(1) {
    width: 13rem;
    font-size: 15px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.import-website-details-container p:nth-child(2) {
    font-size: 14px;
    font-weight: 400;
    color: gray;
}