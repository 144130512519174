.nav-bar {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2%;
    top: 0;
    left: 0;
    z-index: 5;
    width: 96%;
    height: 60px;
    background-image: linear-gradient(90deg, #90a0d0, #90a0d0, #90a0d0, #90a0d0, #90a0d0);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
}

.nav-bar-sec-2 {
    display: flex;
    align-items: center;
    gap: 2rem;
}