.sidebar {
    height: 100%;
    width: 15%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #90a0d0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 0%;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
}


.active-sidebar,
.inactive-sidebar {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    text-decoration: none;
    color: black;
    width: 80%;
    padding: 10px 10%;
    cursor: pointer;

}


.active-sidebar {
    /* background-color: rgb(82, 145, 218); */
    background-color: #a6b5e1;
}


.inactive-sidebar:is(:hover) {
    background-color: #a6b5e1;
}

.sidebar-logo-container {
    width: 100%;
}

.sidebar-logo {
    width: 100%;
    object-fit: contain;

}