.settings-page {
    width: 60%;
    height: 100%;
    padding: 4% 20%;
}

.token-instruction {
    font-size: 14px;
    color: #8d919f;
}

.token-display-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 50%;
    gap: 1rem;
    margin-bottom: 1rem;

}


.api-content-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: #ccd2d7;
    padding: 2rem 1rem;
    border-radius: 5px;

}

.ind-api-content-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.ind-api-content-container label {
    font-weight: 600;
    text-transform: capitalize;
}

.ind-api-content-container p {
    margin: 0;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    border-radius: 5px;
    background-color: white;
    color: #8d919f;
    font-size: 14px;
}


.api-message-container {
    min-height: 45vh;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}