.account-page {
    width: 60%;
    height: 100%;
    padding: 4% 20%;
}


.account-field-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    /* height: 35vh; */
}

.ind-account-field-container {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    width: 50%;
    height: 86px;
}

/* .account-field {
    width: 100%;
    border: 1px solid black;
} */


.profile-update-err-msg {
    font-size: 14px;
    color: crimson;
}