.pageList-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 1rem 0;
}



.ind-page-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 98%;
    gap: 10%;
    padding: 0 10px;
    border-bottom: 1px solid #eff1f3;
    transition: all 0.5s;
    cursor: pointer;
    position: relative;
}

.ind-page-list:is(:hover) {
    background-color: #f9f9f9;
    ;
}

.ind-page-list-detail-container {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    width: 15rem;
}

.ind-page-list-detail-container p:nth-child(1) {
    font-weight: 500;
    max-width: 12rem;
    display: flex;
    align-items: center;
    gap: 0.25rem;
}


.ind-page-list-detail-date {
    /* background-color: #dad7d7; */
    padding: 5px;
    border-radius: 5px;
    font-size: 14px;
    color: #8d919f;
}


.ind-page-list p:nth-child(3),
.ind-page-list p:nth-child(4) {
    font-size: 14px;
    color: #8d919f;
}

.page-create-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}


.ind-page-create-field {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    width: 90%;
    height: 5rem;

}